<template>
	<div class="my-grade">
		<!-- <div class="header-menu va-row">
      <div class="va-row">
         <div class="flex container-ddh-menu xs12 with-bottom va-row">
            <div class="flex xs6 no-padding">
               <h1>Escolas</h1>
            </div>
            <div class="flex xs6 no-padding text-right">
               <br><br>
            
               <button
                  v-on:click="showSchool(emptySchool, 'add')"
                  class="btn btn-change">
                  ADICIONAR ESCOLA
               </button>
            </div>
         </div>
      </div>
      <div class="clearfix"></div>
   </div> -->

		<div class="header-menu va-row">
			<div class="flex md10 offset--md1">
				<div class="header-teacher">
					<div class="va-row">
						<div class="flex xs6 ddh-item">Dentro Educa</div>
						<!-- <div class="flex xs6 ddh-prof">{{userCredential.name}}</div> -->
					</div>

					<div class="va-row">
						<div
							class="flex xs12 text-left school-name"
							v-if="schoolInfo !== null"
						>
							<!-- <span class="back-circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <path fill="#4965bc" fill-rule="evenodd" stroke="#4965bc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"/>
                </svg>
              </span>  -->
							<!-- {{schoolInfo.name}} -->
							{{ userCredential.name }}
						</div>
						<!-- <div class="flex xs6">Professora Andréia Aguiar</div> -->
					</div>
				</div>
			</div>

			<div class="teacher-grade va-row">
				<div class="flex md8 offset--md1">
					<div class="va-row">
						<!-- <div class="flex xs6 teacher-name"> -->

						<menu-tabs
							:items="[
								{ label: 'Minhas turmas', slug: 'grades', isActive: true },
								{
									label: 'Livros personalizados',
									slug: 'infobook',
									isActive: false,
								},
								{
									label: 'Pagamento',
									slug: 'teacher-payment',
									isActive: false,
								},
							]"
							@scrollTo="scrollParent"
							ref="tabs"
							class="container-ddh-menu"
						></menu-tabs>
					</div>
				</div>

				<div class="flex md2">
					<button
						v-on:click="showGrade(emptyGrade, 'add')"
						class="btn btn-change"
					>
						ADICIONAR TURMA
					</button>
				</div>
			</div>
		</div>

		<div class="body-teacher">
			<div class="va-row">
				<div class="flex md10 offset--md1">
					<div class="body-spacing">
						<!-- {{list}} -->

						<div class="panel" v-for="(grade, id) in list" :key="id">
							<h4>
								{{ grade.name }} <span>•</span> Período {{ grade.period }}
								<span>•</span> {{ grade.year }}
							</h4>
							<hr />

							<div class="va-row">
								<div class="flex xs6">
									<div class="va-row box-blue">
										<div class="flex xs6">
											<span>Alunos cadastrados</span>
											<h1>{{ grade.students_count }}</h1>
										</div>

										<div class="flex xs6">
											<span>Livros da Turma</span>
											<h1>{{ grade.books_complete_count }}</h1>

											<router-link
												class="btn btn-white"
												:to="{ name: 'grade', params: { grade: grade } }"
											>
												CADASTRAR ALUNOS

												<div
													class="flag"
													v-if="showPopUp && id == 0"
													@mouseleave="hidePopUp()"
												>
													<div class="va-row">
														<div class="flex xs2 no-padding icon">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="36"
																height="36"
																viewBox="0 0 36 36"
															>
																<path
																	fill="#fff"
																	fill-rule="evenodd"
																	d="M13.73 7h9.54L30 13.73v9.54L23.27 30h-9.54L7 23.27v-9.54L13.73 7zm5.807 17.35a1.47 1.47 0 0 0 .432-1.038 1.471 1.471 0 0 0-2.51-1.041 1.48 1.48 0 0 0-.428 1.04c0 .386.155.765.429 1.038.276.274.655.432 1.04.432s.764-.158 1.037-.432zM17.35 13.157v6.859a1.15 1.15 0 1 0 2.3 0v-6.859a1.15 1.15 0 1 0-2.3 0z"
																/>
															</svg>
														</div>
														<div class="flex xs10">
															<b>Cadastre os alunos</b>
															<p>
																Agora você precisar cadastrar os seus alunos e
																depois dar andamento dentro de sala de aula com
																a lista de atividades.
															</p>
														</div>
													</div>
												</div>
											</router-link>
										</div>
									</div>
								</div>

								<div class="flex xs6">
									<div class="info-box">
										Para imprimir, é preciso que todos os alunos estejam com os
										livros criados. <br /><br />
										Você tem até {{ grade.deadline }} para criar os livros com
										seus alunos.
									</div>
								</div>

								<div></div>
							</div>
						</div>

						<div class="va-row questions">
							<div class="flex xs12">
								<h1>Perguntas frequentes</h1>
								<div>
									<vuestic-tree-root ref="treeView">
										<vuestic-tree-category
											v-for="(question, id) in questions"
											:key="id"
										>
											<div class="va-row" slot="content-line">
												<div class="flex xs3 no-padding">
													{{ question.question }}
												</div>
											</div>
											<vuestic-tree-node>
												<div class="va-row">
													<div class="flex xs3 no-padding">
														{{ question.answer }}
													</div>
												</div>
											</vuestic-tree-node>
										</vuestic-tree-category>
									</vuestic-tree-root>
								</div>
							</div>
						</div>

						<vuestic-modal
							:show.sync="show"
							v-bind:large="true"
							ref="gradeModal"
							:okText="'modal.confirm' | translate"
							:cancelText="'modal.cancel' | translate"
							:noButtons="true"
						>
							<div slot="title" v-if="gradeModalType === 'view'">
								Visualizar turma
							</div>
							<div slot="title" v-if="gradeModalType === 'edit'">
								Editar turma
							</div>
							<div slot="title" v-if="gradeModalType === 'delete'">
								Excluir turma
							</div>
							<div slot="title" v-if="gradeModalType === 'add'">
								Adicionar turma
							</div>

							<div v-if="gradeSelected !== null">
								<grade-edit
									:grade="gradeSelected"
									:school="schoolSelected"
									:typeSend="'post'"
									v-if="gradeModalType === 'add'"
								></grade-edit>
								<grade-edit
									:grade="gradeSelected"
									:school="schoolSelected"
									:typeSend="'put'"
									v-if="gradeModalType === 'edit'"
								></grade-edit>

								<grade-view
									:grade="gradeSelected"
									v-if="gradeModalType === 'view'"
								></grade-view>
								<grade-delete
									:grade="gradeSelected"
									v-if="gradeModalType === 'delete'"
								></grade-delete>
							</div>
						</vuestic-modal>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "schools",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				userCredential: "userCredential",
				// ...
			}),
		},

		mounted() {
			const self = this;

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// this.$root.$on('closeModal', () => {
			//     self.closeModal();
			// })

			// this.$root.$on('addSchool', (school) => {
			//     self.list.push(school)
			// })

			// this.$root.$on('editSchool', (school) => {
			//     var result = self.list.filter(item => {
			//       return item.id === school.id
			//     })

			//     result = school
			// })

			// this.$root.$on('addSchool', (school) => {
			//     self.list.push(school)
			// })

			// this.$root.$on('editSchool', (school) => {
			//     var result = self.list.filter(item => {
			//       return item.id === school.id
			//     })

			//     result = school
			// })

			//alert(this.userCredential.name);

			let config = {
				headers: {
					Authorization: "Bearer " + this.userCredential.token,
				},
			};

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/grades?page=1&limit=100&sort=name asc&active=true&school_id=" +
						this.userCredential.school_id,
					config
				)
				.then((response) => {
					self.list = response.data.data.grades;
				})
				.catch((e) => {
					this.errors.push(e);
				});

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/schools/" +
						this.userCredential.school_id,
					config
				)
				.then((response) => {
					self.schoolInfo = response.data.data.school;
					self.schoolSelected = self.schoolInfo;
					this.emptyGrade.project_id = this.schoolSelected.project_id;
					this.emptyGrade.school_id = this.schoolSelected.school_id;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},

		//props: ['address', 'subscription'],

		data() {
			return {
				showPopUp: true,

				list: null,

				schoolInfo: null,
				gradeInfo: null,

				emptySchool: {
					name: "",
					address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},
					contact_name: "",
					contact_email: "",
					contact_phone: "",
					type: 0,
					project_id: 0,
				},

				emptyGrade: {
					// role_id: "20",
					project_id: "1",
					grade_type_id: 1,
					name: "",
					grade_type_id: "",
					period_id: "",
					year: "",
					school_id: "",
					size: "",
					deadline: "",
					teacher: {
						//username: "",
						// password: "",
						//birthdate: "",
						email: "",
						name: "",
						phone: "",
						gender: "",
						//send_email: "",
						//auto_password: "",
					},
				},

				schoolModalType: "view",
				schoolSelected: null,

				gradeModalType: "view",
				gradeSelected: null,

				files: "",
				show: false,

				questions: [
					{
						question: "Pergunta",
						answer: "Resposta",
					},
				],

				// subscriptionsData: null,
				// addressEdit: {
				//   addressLabel: ''
				// },
				// subscriberEditing: false,
				// subscriptionSend: {
				//   'subscriptions': [],
				//   'address': this.addressEdit
				// },
				// percentCall: 0,
				// isCreation: true,

				// openAddressField: false,

				// cepSearch: false,
				// errors: []
			};
		},
		methods: {
			scrollParent(slug) {
				this.$router.push({ name: slug });
			},

			showSchool(school, type) {
				this.schoolModalType = type;
				this.schoolSelected = school;
				this.$refs.largeModal.open();
			},

			showGrade(grade, type) {
				this.gradeModalType = type;
				this.gradeSelected = grade;
				//this.schoolSelected = school
				this.$refs.gradeModal.open();
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}
			},

			// closeModal(){
			//   this.$refs.largeModal.close()
			//   this.$refs.gradeModal.close()
			// },

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			hidePopUp() {
				this.showPopUp = false;
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.vuestic-tree-category {
		border-top: 1px solid #dfdae4;
		padding-top: 25px !important;
	}

	.questions {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: #68596e;
		}

		::v-deep .square-with-icon {
			background-color: transparent;
			color: #00dcde;
			font-size: 35px;
		}
	}
	.info-box {
		border-radius: 10px;
		background-color: #f8f7fc;
		height: 215px;
		border-radius: 10px;
		background-color: #f8f7fc;
		padding: 34px;
	}
	.btn-white {
		position: relative;
	}
	.flag {
		box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
		background-color: #4567ba;
		max-width: 310px;
		padding: 10px;
		width: 310px;
		position: absolute;
		color: #cbe1ff;
		font-size: 14px;
		font-family: "Nunito";
		border-radius: 10px;
		display: block;
		margin-left: -90px;
		text-align: left;
		font-weight: 200;
		bottom: 63px;

		p {
			margin-bottom: 0px !important;
		}

		.icon {
			align-items: center;
			display: flex;
		}

		b {
			color: #cbe1ff !important;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -20px;
			width: 0px;
			height: 0px;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #4567ba;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.body-spacing {
		max-width: 1160px;
		margin: 0 auto;
	}
	::v-deep .box-blue {
		height: 215px;
		border-radius: 10px;
		background-color: #00dcde;
		padding: 25px;
		font-size: 16px;
		color: #ffffff;

		h1 {
			font-family: Nunito;
			font-size: 60px;
			font-weight: 800;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
		}
	}
	::v-deep .vuestic-tabs .nav {
		padding-top: 0px !important;
	}

	::v-deep .vuestic-tabs {
		padding: 0px !important;
	}

	.phrase-secure {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #68596e;
		margin-top: 20px !important;
		// margin-bottom:20px !important;

		b {
			color: #68596e !important;
		}

		.btn {
			height: 65px;
			width: 100%;
			max-width: 357px;
			font-size: 14px;
			border-radius: 100px;
		}
	}

	.custom-teacher-table {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afa2ba;

		b {
			font-size: 14px !important;
		}

		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
		}
	}
	.teacher-flag {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		background-color: #9378f0;
		line-height: 30px;

		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}
	.book-covers {
		justify-content: flex-end;
	}
	.empty-book {
		width: 100%;
		border: 1px solid #afa2ba;
		height: 100%;
		height: 187px;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		display: flex;
		font-size: 25px;
	}
	.btn-micro-edit {
		font-family: "Nunito";
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.67px;
		text-align: center;
		color: #4965bc;
		background: none;
		border: none;
	}
	.student-name {
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: left;
		display: flex;
	}
	.student-code {
		font-family: Nunito;
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: 1.11px;
		text-align: right;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: right;
		display: flex;
	}

	.teacher-grade {
		background-color: white;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		.btn {
			width: 275px;
			height: 45px;
			border-radius: 100px;
			background-color: #9378f0;
			background-image: none;
			position: relative;

			@media (max-width: 767px) {
				display: block;
				margin: 0 auto;
				top: 15px;
			}

			span {
				font-size: 17px;
				position: absolute;
				top: 13px;
				right: 23px;
			}
		}

		.teacher-name {
			line-height: 45px;
		}
	}

	.panel-gray {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		overflow: hidden;

		.btn {
			border-radius: 100px;
			background-color: #4965bc;
			height: 65px;
			border-radius: 100px;
			background-color: #4965bc;
			padding-left: 70px;
			position: relative;
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;
			line-height: 40px;

			span {
				width: 45px;
				height: 45px;
				border-radius: 100px;
				background-color: #ffffff;
				font-size: 41px;
				display: inline-block;
				color: #4965bc;
				line-height: 43px;
				position: absolute;
				left: 8px;
				top: 8px;
			}
		}
	}

	.body-teacher {
		margin-top: 20px;

		.body-spacing .panel {
			padding: 10px 25px;

			h4 {
				margin-top: 10px;
				margin-bottom: 15px;
				font-family: Nunito;
				font-size: 20px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #66596e;
			}
		}
	}

	.body-student {
		border-bottom: 1px solid #dfdae4;
	}

	.info-grade {
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
	}

	.school-name {
		font-family: "Nunito";
		font-size: 40px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;

		.back-circle {
			width: 60px;
			height: 60px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			display: inline-block;
			text-align: center;
			border-radius: 30px;
		}
	}
	.my-grade {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
	}

	.header-teacher {
		.ddh-item {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
		}

		.ddh-prof {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: right;
			color: #ffffff;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: #4965bc;
		margin-bottom: 20px;
		display: block;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	// .address-info{
	//   margin-bottom:15px;
	// }
	// .editing{
	//   margin-top:15px;
	// }
	// .radio-button-wrapper{
	//   padding: 14px 11px;
	//   margin-top: 10px;

	//   @media(max-width: 990px){
	//     padding: 20px 19px;
	//     margin-top: 10px;
	//   }
	// }
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	// Default of page

	// .my-data{

	//   /* Facebook bar */
	//   .facebook-tag{
	//     height: 65px;
	//     border-radius: 5px;
	//     background-color: #3a5998;
	//     color:white;
	//     padding: 14px 20px;
	//     margin-bottom: 20px;

	//     svg{
	//       font-size: 26px;
	//       margin-right: 0px;
	//       float: left;

	//       path{
	//         fill: #fff;
	//       }
	//     }

	//     .name{
	//       display: inline-block;
	//       margin-top: 1px;
	//       line-height: 37px;
	//     }

	//     .facebook-toggle{
	//       float: right;
	//       display: block;
	//       margin-top: 5px;
	//     }
	//   }

	// }

	// .change-password{
	//   border-radius: 10px;
	//   border: solid 2px #f0ebf5;
	//   background-color: #ffffff;
	//   padding: 24px 20px;
	//   position: relative;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 7px;
	//     font-family: 'Roboto';

	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//   }

	//   h5{
	//     font-size: 14px;
	//     font-weight: normal;
	//     font-style: normal;
	//     font-stretch: normal;
	//     letter-spacing: normal;
	//     color: #afa2ba;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .floated-icon{
	//     float: left;
	//     width: 36px;
	//     height: 36px;
	//     font-size:30px;

	//     svg{
	//       position: relative;
	//       top: -5px;
	//       left: -3px;

	//       path{
	//         fill: #afa2ba;
	//       }
	//     }
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	// }

	// .my-data-table{
	//   h4{
	//     margin-top: 50px;
	//     margin-bottom: 20px;
	//   }
	// }

	// .fiscal-notes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   .body-spacing{
	//     padding: 30px 30px 10px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//     //  div:last-child{
	//     //   margin-bottom: 0px;
	//     // }
	//   }

	//   hr{
	//     border-top: solid 1px #f0ebf5;
	//     margin-top: 0px;
	//     margin-bottom: 0px;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   .company-code{
	//     text-transform: capitalize;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   // .panel:last-child{
	//   //   margin-bottom: 0px;
	//   // }
	// }

	// .address-boxes{
	//   border-radius: 10px;
	//   border: solid 1px #f0ebf5;
	//   background-color: #ffffff;
	//   position: relative;
	//   border: solid 2px #f0ebf5;

	//   h4{
	//     font-size: 16px;
	//     color: #000000;
	//     margin-top: 0px;
	//     margin-bottom: 10px;
	//     font-family: 'Roboto';
	//     font-weight: bold;
	//     font-style: normal;
	//     font-stretch: normal;
	//     line-height: normal;
	//     letter-spacing: normal;
	//   }

	//   svg{
	//     path{
	//       fill: #afa2ba;
	//     }
	//   }

	//   .clube{
	//     svg{
	//     path{
	//         fill: #fff;
	//       }
	//     }
	//   }

	//   .body-spacing{
	//     padding: 30px 30px;

	//     @media(max-width:990px){
	//       padding: 25px 15px 15px 15px;
	//     }

	//   }

	//   hr{
	//     border-top: solid 2px #f0ebf5;
	//   }

	//   .dual-inputs{
	//     padding: 0px 8px;
	//     margin-bottom: 20px;
	//   }

	//   .new-password-group{
	//     margin-top: 20px;
	//     display: block;
	//   }

	//   .editor{
	//     right: 18px;
	//     top: 20%;
	//   }

	//   ::v-deep .form-group{
	//     margin-bottom: 20px;
	//     padding: 22px 20px;

	//     ::v-deep &:last-child{
	//       margin-bottom:20px;
	//     }
	//   }

	//   .more-address{
	//     border-radius: 10px;
	//     border: solid 1px #f0ebf5;
	//     background-color: #ffffff;
	//   }

	//   .address-row{
	//     padding: 16px 0px;
	//     position: relative;
	//     border-bottom: solid 1px #f0ebf5;
	//     b{
	//       font-size: 16px;
	//       color: #000000;
	//     }
	//     :last-child{
	//       border-bottom: none;
	//     }

	//   }

	// }

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		// border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;
		box-shadow: 0 10px 25px 0 #e2ddf6;
		background-color: #ffffff;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
